import {AbstractModel} from './abstract.model';

export interface ProjectItemModel extends AbstractModel {
  project: {
    id: number;
    code?: string;
    name?: string;
  };
  package: {
    id: number;
    code?: string;
    name?: string;
  };
  service: {
    id: number;
    code?: string;
    name?: string;
    cost?: number;
  };
  resultType?: {
    id: number;
    code?: string;
    name?: string;
  };
  count: number;
  duration: number;
  maxCount: number;
  order?: number;
}
