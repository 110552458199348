import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiListDataService } from './api.list-data.service';

@Injectable({
    providedIn: 'root',
})
export class ApiServiceService extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Service/');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiServiceWebregisterService extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Service/webregister');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiServiceLookupService extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Service/Lookup/');
    }
}
