import { AbstractModel } from './abstract.model';

export interface UserModel extends AbstractModel {
    email: string;
    phoneNumber?: string;
    organization: {
        id?: number;
        name?: string;
    };
    userName?: string;
    lastName: string;
    firstName: string;
    middleName: string;
    role?: number;
    roles?: number[];
    specialRights?: number[];
    id?: number;
    specialtyId?: number;
    projects?: any;
    notActive?: boolean;
    additionalInformation?: string;
    LastName?: string;
    FirstName?: string;
    serviceCount?: number;
    invalid?: boolean;
}
